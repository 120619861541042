import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { ROUTE_LOG_IN } from "../utils/constants";

const PrivateRoute = () => {
  const token = localStorage.getItem("token");
  if (!token) return <Navigate to={`/${ROUTE_LOG_IN}`} />;

  return <Outlet />;
};

export default PrivateRoute;
