import axios from "axios";
import { BASE_URL } from "../utils/constants";

const createHeaders = () => {
  const authToken = localStorage.getItem("token");
  return {
    headers: {
      "X-AUTH-TOKEN": authToken,
    },
  };
};

export const signInUser = async (username, password) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/sign_in`, {
        email: username,
        password: password
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error.response.data.error };
  }
};

function compareBusinessName(a, b) {
  if (a.attributes.business_name < b.attributes.business_name) {
    return -1;
  } else if (a.attributes.business_name > b.attributes.business_name) {
    return 1;
  } else {
    return 0;
  }
}

export const getBusinesses = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}/get_business`,
      createHeaders()
    );
    return response.data.sort(compareBusinessName);
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getBusinessesImage = async (businessId) => {
  try {
    const config = {
      ...createHeaders(),
      responseType: "arraybuffer",
    };

    const response = await axios.get(
      `${BASE_URL}/get_business_image?business_id=${businessId}`,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching business image:", error);
    throw error;
  }
};

function compareStoreName(a, b) {
  if (a.attributes.store_name < b.attributes.store_name) {
    return -1;
  } else if (a.attributes.store_name > b.attributes.store_name) {
    return 1;
  } else {
    return 0;
  }
}

export const getSchedules = async (businessId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/get_schedules?business_id=${businessId}`,
      createHeaders()
    );
    return response.data.sort(compareStoreName);
  } catch (error) {
    console.error("Error fetching Schedules:", error);
    throw error;
  }
};

export const getDeviceList = async (businessId, scheduleId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/${businessId}/${scheduleId}/devices`,
      createHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Devices:", error);
    throw error;
  }
};

export const getDeviceInfo = async (deviceId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/device/${deviceId}`,
      createHeaders()
    );
    return response;
  } catch (error) {
    console.error("Error fetching Device:", error);
    throw error;
  }
};

export const deRegisterDevice = async (deviceId) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/deregister_device/${deviceId}`,
      {},
      createHeaders()
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error.response.data.error };
  }
};

export const registerDevice = async (
  businessId,
  scheduleId,
  deviceName,
  deviceSerialNo
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/register_device?business_id=${businessId}&schedule_id=${scheduleId}&serial_no=${deviceSerialNo}&device_name=${deviceName}`,
      {},
      createHeaders()
    );

    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error.response.data.message || error.response.data.error };
  }
};

export const uploadFile = async (configFile, fileType) => {
  try {
    const authToken = localStorage.getItem("token");

    const formData = new FormData();
    formData.append("config_file", configFile);
    formData.append("file_type", fileType);

    const config = {
      headers: {
        "X-AUTH-TOKEN": authToken,
        "Content-Type": "multipart/form-data",
      },
    };

    const response = await axios.post(
      `${BASE_URL}/upload_config`,
      formData,
      config
    );

    return { data: response.data.success, error: null };
  } catch (error) {
    console.error("Error uploading file:", error);
    return { data: null, error: error.response.data.error };
  }
};

export const getDldList = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/dld_list`, createHeaders());
    return { data: response.data, error: null };
  } catch (error) {
    console.error("Error fetching DLD list:", error);
    return { data: null, error: error.response.data.error };
  }
};

export const getComplianceWarnings = async (
  businessId,
  scheduleId,
  startTime,
  endTime,
  page
) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/compliance_warning?business_id=${businessId}&schedule_id=${scheduleId}&start_time=${startTime}&end_time=${endTime}&page=${page}`,
      createHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching compliance warnings:", error);
    throw error;
  }
};
export const getDeviceAlerts = async (deviceId, startTime, endTime, page) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/device_alerts/${deviceId}?page=${page}&start_time=${startTime}&end_time=${endTime}`,
      createHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Device Alerts:", error);
    throw error;
  }
};
