import React, { useEffect, useState } from "react";
import styles from "./NavBar.module.css";
import logo from "../../../assets/navlogo.png";
import NavBarDropdown from "./NavBarDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { ROUTE_HOME } from "../../../utils/constants";
import ScheduleSelector from "../../common/ScheduleSelectorDropdown/ScheduleSelector";

function NavBar({
  businessName,
  businessId,
  showScheduleSelector,
  onSelectSchedule,
  selectedComponent,
  schedules,
  selectedSchedule,
}) {
  const [showDropdown, setShowDropdown] = useState(false);

  const navigate = useNavigate();

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const handleOutsideClick = (event) => {
    if (!event.target.closest(`.${styles.profileImage}`)) {
      setShowDropdown(false);
    }
  };
  const handleHomeRoute = (event) => {
    if (localStorage.getItem("token")) {
      navigate(`/${ROUTE_HOME}`);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <nav className={styles.navBar}>
      <ul className={styles.navList}>
        <li className={styles.navItemIcon} onClick={handleHomeRoute}>
          <img src={logo} alt="icon"></img>
        </li>

        {(selectedComponent === "dashboard" ||
          selectedComponent === "complianceError") &&
          showScheduleSelector && (
            <li className={styles.selector}>
              <ScheduleSelector
                businessName={businessName}
                onSelectSchedule={onSelectSchedule}
                schedules={schedules}
                selectedSchedule={selectedSchedule}
              />
            </li>
          )}
      </ul>
      <div className={styles.navItemProfile}>
        <div className={styles.profileWrapper} onClick={toggleDropdown}>
          <FontAwesomeIcon
            icon={faCircleUser}
            alt="Profile"
            className={styles.profileImage}
          />
          {showDropdown && <NavBarDropdown showDropdown={showDropdown} />}
        </div>
      </div>
    </nav>
  );
}
export default React.memo(NavBar);
