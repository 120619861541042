import React from "react";
import styles from "./DeRegisterConfirmationDialogBox.module.css";

const DeRegisterConfirmationDialogBox = ({
  deviceName,
  onClose,
  onDeregister,
  deviceId,
}) => {
  const handleDeregister = () => {
    onDeregister(deviceId);
  };

  return (
    <div className={styles.overlay}>
      <div className={styles.dialog}>
        <div className={styles.title}>
          Are you sure you want to deregister {deviceName}?
        </div>
        <div className={styles.buttons}>
          <button onClick={onClose} className={styles.closeButton}>
            Close
          </button>
          <button
            className={styles.deregisterButton}
            onClick={handleDeregister}
          >
            Deregister
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeRegisterConfirmationDialogBox;
