import React, { useState, useEffect } from "react";
import styles from "./Login.module.css";
import logo from "./../../assets/logo-lifelenz-white.svg";
import ErrorDialog from "../common/DialogBox/ErrorDialog";
import { useNavigate } from "react-router-dom";
import { ROUTE_HOME } from "../../utils/constants";
import * as Yup from "yup";
import { useFormik } from "formik";
import { signInUser } from "../../api/api";
import { Helmet } from "react-helmet";

const LogIn = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate(`/${ROUTE_HOME}`);
    }
  });

  const validationSchema = Yup.object().shape({
    username: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const { data, error } = await signInUser(
          values.username,
          values.password
        );
        if (error) {
          setError(error);
        } else {
          const { auth_token } = data;
          localStorage.setItem("token", auth_token);
          navigate(`/${ROUTE_HOME}`);
        }
      } catch (error) {
        console.log(error);
        setError("An error occurred while signing in");
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div className={styles.Container}>
      <Helmet>
        <title>Login | LIFELENZ</title>
      </Helmet>
      <div className={styles.lifelenzLogo}>
        <img src={logo} alt="LIFELENZ WebAdmin Loading" />
      </div>

      <div className={styles.formContainer}>
        <form onSubmit={formik.handleSubmit}>
          <div className={styles.formGroup}>
            <div className={styles.label}>
              <label htmlFor="emailId"> Email</label>
            </div>

            <input
              className={`${styles.input} ${
                formik.touched.username &&
                formik.errors.username &&
                styles.invalidInput
              }`}
              type="email"
              id="emailId"
              name="username"
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={"email"}
              required
            />
            {formik.touched.username && formik.errors.username && (
              <span className={styles.errorMsg}>{formik.errors.username}</span>
            )}
          </div>

          <div className={styles.formGroup}>
            <div className={styles.label}>
              <label htmlFor="password">Password</label>
            </div>
            <input
              className={`${styles.input} ${
                formik.touched.password &&
                formik.errors.password &&
                styles.invalidInput
              }`}
              type="password"
              id="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={"password"}
            />
            {formik.touched.password && formik.errors.password && (
              <span className={styles.errorMsg}>{formik.errors.password}</span>
            )}
          </div>

          <div>
            <button type="submit" className={styles.submit} disabled={loading}>
              {loading ? <div className={styles.spinner}></div> : "Log In"}
            </button>
          </div>
        </form>
      </div>
      {error && (
        <ErrorDialog
          title={error.title}
          detail={error.detail}
          onClose={() => setError(null)}
        />
      )}
    </div>
  );
};
export default LogIn;
