import React, { useState, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Dashboard.module.css";
import NavBar from "../common/NavBar/NavBar";
import SideBar from "../common/SideBar/SideBar";
import DeviceMonitoring from "../DeviceList/DeviceMonitoring";
import DeviceRegister from "../DeviceRegistration/DeviceRegister";
import DeviceDetails from "../DeviceDetails/DeviceDetails";
import { Helmet } from "react-helmet";
import { getSchedules } from "../../api/api";
import DldFileList from "../DldFileList/DldFileList";
import ComplianceError from "../ComplianceError/ComplianceError";
import { ROUTE_DASHBOARD } from "../../utils/constants";

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const businessName = params.get("businessName");
  const businessId = params.get("businessId");
  const originalScheduleId = params.get("scheduleId");

  const [selectedComponent, setSelectedComponent] = useState("dashboard");
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [showScheduleSelector, setShowScheduleSelector] = useState(true);

  const [schedules, setSchedules] = useState([]);

  const handleComponentSelect = useCallback((componentName) => {
    setSelectedComponent(componentName);
    setSelectedDevice(null);
    setShowScheduleSelector(componentName === "dashboard" || "complianceError");
  }, []);

  const handleDeviceSelect = useCallback((deviceId) => {
    setSelectedDevice(deviceId);
  }, []);
  const handleSelectSchedule = useCallback((schedule) => {
    setSelectedSchedule(schedule);
    navigate(
      `/${ROUTE_DASHBOARD}?businessName=${encodeURIComponent(businessName)}&businessId=${encodeURIComponent(businessId)}&scheduleId=${schedule.id}`,
       { replace: true })
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getSchedules(businessId);
        setSchedules(data);
        if (data.length > 0) {
          var foundSchedule = undefined;
          if (originalScheduleId != null) {
            foundSchedule = data.find( s => originalScheduleId === s.id)
          }
          if (foundSchedule === undefined) {
            foundSchedule = data[0];
          }
          handleSelectSchedule(foundSchedule);
        }
      } catch (error) {
        console.error("Error fetching schedules:", error);
      }
    };
    fetchData();
  }, [businessId, handleSelectSchedule]);
  return (
    <>
      <Helmet>
        <title>Device Monitoring | LIFELENZ</title>
      </Helmet>
      <NavBar
        businessName={businessName}
        businessId={businessId}
        onSelectSchedule={handleSelectSchedule}
        selectedSchedule={selectedSchedule}
        selectedComponent={selectedComponent}
        showScheduleSelector={showScheduleSelector}
        schedules={schedules}
      />
      <div>
        <SideBar onSelect={handleComponentSelect} />
        <div className={styles.dashboardContainer}>
          {selectedComponent === "dashboard" && !selectedDevice && (
            <DeviceMonitoring
              onSelectDevice={handleDeviceSelect}
              schedule={selectedSchedule}
              businessId={businessId}
            />
          )}
          {selectedComponent === "register" && (
            <DeviceRegister
              businessId={businessId}
              businessName={businessName}
              setSelectedComponent={setSelectedComponent}
            />
          )}
          {selectedDevice && (
            <DeviceDetails
              deviceId={selectedDevice}
              schedule={selectedSchedule}
              onClose={() => setSelectedDevice(null)}
            />
          )}
          {selectedComponent === "complianceError" && (
            <ComplianceError
              schedule={selectedSchedule}
              businessId={businessId}
            />
          )}

          {selectedComponent === "dldList" && <DldFileList />}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
