import "./App.css";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { ROUTE_DASHBOARD, ROUTE_HOME, ROUTE_LOG_IN } from "./utils/constants";
import SplashScreen from "./components/SplashScreen/SplashScreen";
import LogIn from "./components/LogIn/Login";
import Home from "./components/Home/Home";
import PrivateRoute from "./protectedRoutes/privateRoute";
import Dashboard from "./components/Dashboard/Dashboard";
import { Tooltip } from "react-tooltip";

function App() {
  return (
    <>
      <Tooltip id="my-tooltip" />
      <Routes>
        <Route path="/" Component={SplashScreen} />

        <Route path={`/${ROUTE_LOG_IN}`} Component={LogIn} />
        <Route element={<PrivateRoute />}>
          <Route path={`/${ROUTE_HOME}`} Component={Home} />
          <Route path={`/${ROUTE_DASHBOARD}`} Component={Dashboard} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
