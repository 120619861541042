import React, { useEffect, useState, useRef } from "react";
import styles from "./DldFileList.module.css";
import { getDldList, uploadFile } from "../../api/api";
import FileUploadDialogBox from "../common/DialogBox/FileUploadDialogBox/FileUploadDialogBox";
import WarningDialogBox from "../common/DialogBox/WarningDialogBox/WarningDialogBox";
import { Helmet } from "react-helmet";

const DldFileList = () => {
  const [uploads, setUploads] = useState([]);
  const [uploadMessage, setUploadMessage] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const fileInputRef = useRef(null);
  const [fileToUpload, setFileToUpload] = useState(null);

  const fetchData = async () => {
    try {
      const data = await getDldList();
      setUploads(data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching DLD list:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleFileChange = async (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      setFileToUpload(uploadedFile);
      setShowWarningDialog(true);
    }
  };

  const handleUpload = async () => {
    if (fileToUpload) {
      try {
        const fileType = "dld";
        const { data, error } = await uploadFile(fileToUpload, fileType);
        if (data) {
          setUploadMessage(data);
          setShowDialog(true);
          fetchData();
        } else {
          setUploadMessage(error);
          setShowDialog(true);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
    setShowWarningDialog(false);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const handleWarningClose = () => {
    setShowWarningDialog(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date) ? date.toISOString().split("T")[0] : "N/A";
  };

  let content;
  if (loading) {
    content = (
      <tr>
        <td colSpan="5" className={styles.spinner}>
          <div className={styles.loader}></div>
        </td>
      </tr>
    );
  } else if (uploads?.length === 0) {
    content = (
      <tr className={styles.noData}>
        <td colSpan="5">No DLD file uploaded</td>
      </tr>
    );
  } else {
    content = uploads?.map((upload, index) => (
      <tr key={index}>
        <td>{upload.filename}</td>
        <td>{upload?.created_at ? formatDate(upload.created_at) : "N/A"}</td>
        <td>
          {index === 0
            ? `Latest (${parseFloat(upload.version).toFixed(1)})`
            : parseFloat(upload.version).toFixed(1)}
        </td>
      </tr>
    ));
  }

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className={styles.container}>
      <Helmet>
        <title>DLD File Upload | LIFELENZ</title>
      </Helmet>
      <section className={styles.tableHeader}>
        <div className={styles.headerContent}>
          <span className={styles.headerSpan}>
            Time Clock | DLD File Upload
          </span>
        </div>
      </section>
      <section className={styles.warningMessageContainer}>
        <span className={styles.warningMessage}>
          Warning: uploading new DLD file will affect all time clock devices
        </span>
      </section>
      <section className={styles.uploadContainer}>
        <input
          ref={fileInputRef}
          type="file"
          style={{ display: "none" }}
          onChange={handleFileChange}
          accept=".dld"
        />
        <div className={styles.spanContainer}>
          <span onClick={handleBrowseClick}>Drop files to upload</span>
          <span className={styles.orSpan}>
            or
            <a
              className={styles.browseSpan}
              href="#"
              onClick={handleBrowseClick}
            >
              browse
            </a>
          </span>
        </div>
      </section>
      <main className={styles.mainTable}>
        <section className={styles.tableBody}>
          <table className={styles.fileUploadTable}>
            <thead className={styles.tHead}>
              <tr>
                <th>File Name</th>
                <th>Time Uploaded</th>
                <th>Version</th>
              </tr>
            </thead>
            <tbody>{content}</tbody>
          </table>
        </section>
      </main>
      {showDialog && (
        <FileUploadDialogBox
          message={uploadMessage}
          onClose={handleCloseDialog}
        />
      )}
      {showWarningDialog && (
        <WarningDialogBox
          onUpload={handleUpload}
          onClose={handleWarningClose}
        />
      )}
    </div>
  );
};

export default DldFileList;
