// REACT_APP_ATS_SERVICE_URL is set during CI build
// It is read from the ATS_SERVICE_URL environment variables at build time
export const ATS_SERVICE_URL_REPLACEABLE = "LIFELENZ_REPLACE_ME_ATS_SERVICE_URL";
export const ATS_SERVICE_URL = ATS_SERVICE_URL_REPLACEABLE.startsWith("LIFELENZ") ? (process.env.REACT_APP_ATS_SERVICE_URL ?? "http://localhost:3200") : ATS_SERVICE_URL_REPLACEABLE;

export const ROUTE_LOG_IN = "log-in";

export const ROUTE_HOME = "home";

export const ROUTE_DASHBOARD = "dashboard";

export const BASE_URL = ATS_SERVICE_URL + "/api";
