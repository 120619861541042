import React, { useState, useEffect } from "react";
import styles from "./DeviceMonitoring.module.css";
import { getDeviceList } from "../../api/api";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt
} from "@fortawesome/free-solid-svg-icons";

export default function DeviceMonitoring({
  onSelectDevice,
  schedule,
  businessId,
}) {
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true);
    try {
      if (schedule) {
        const data = await getDeviceList(businessId, schedule.id);
        setDevices(data.data);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching device list:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [schedule, businessId]);

  const getStatusColor = (status) => {
    return status === "ACTIVE" ? styles.active : styles.inactive;
  };

  const handleRowClick = (deviceId) => {
    onSelectDevice(deviceId);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date)
      ? `${date.toISOString().split("T")[0]} ${
          date.toTimeString().split(" ")[0]
        }`
      : "N/A";
  };
  let content;
  if (loading) {
    content = (
      <tr className={styles.bodyTr}>
        <td colSpan="5" className={styles.spinner}>
          <div className={styles.loader}></div>
        </td>
      </tr>
    );
  } else if (devices.length === 0) {
    content = (
      <tr className={styles.bodyTr}>
        <td colSpan="5">No registered devices for this schedule location</td>
      </tr>
    );
  } else {
    content = devices.map((device, index) => (
      <tr
        className={styles.bodyTr}
        key={device.id}
        onClick={() => handleRowClick(device.id)}
      >
        <td>{device.serial_no}</td>
        <td>{device.device_name}</td>
        <td>
          <div>
            <button className={getStatusColor(device?.status)}>
              {device?.status}
            </button>
          </div>
        </td>
        <td>
          {device?.last_heartbeat ? formatDate(device.last_heartbeat) : "N/A"}
        </td>
        <td>{device.last_event}</td>
      </tr>
    ));
  }

  return (
    <div className={styles.container}>
      <Helmet>
        <title>Device Monitoring | LIFELENZ</title>
      </Helmet>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <span className={styles.headerSpan}>
            Time Clock | Device Monitoring
          </span>
          <span>
          <FontAwesomeIcon
              icon={faBolt}
              alt="fetch"
              className={[styles.fetchButton, loading && styles.fetchButtonLoading].join(" ")}
              onClick={fetchData}
            />
          </span>
        </div>
      </div>
      <main className={styles.mainTable}>
        <section className={styles.tableBody}>
          <table className={styles.table}>
            <colgroup>
              <col style={{width: "20%"}}></col>
              <col style={{width: "20%"}}></col>
              <col style={{width: "20%"}}></col>
              <col style={{width: "20%"}}></col>
              <col style={{width: "20%"}}></col>
            </colgroup>
            <thead className={styles.tHead}>
              <tr>
                <th>Device Serial No</th>
                <th>Device Name</th>
                <th>Communication Status</th>
                <th>Last Heartbeat</th>
                <th>Last Event</th>
              </tr>
            </thead>
            <tbody className={styles.tBody}>{content}</tbody>
          </table>
        </section>
      </main>
    </div>
  );
}
