import React, { useEffect, useState } from "react";
import NavBar from "../common/NavBar/NavBar";
import styles from "./Home.module.css";
import { getBusinesses } from "../../api/api";

import BusinessCard from "../common/Card/BusinessCard";
import { Helmet } from "react-helmet";

export default function Home() {
  const [businesses, setBusinesses] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getBusinesses();
        setBusinesses(data);
      } catch (error) {}
    };

    fetchData();
  }, []);

  return (
    <div className={styles.main}>
      <Helmet>
        <title>Home | LIFELENZ</title>
      </Helmet>
      <NavBar />
      <div className={styles.header}>
        <h1>Business Explorer</h1>
        <hr className={styles.hr} />
      </div>

      <div className={styles.container}>
        <div className={styles.ribbon}>
          <p className={styles.p}>Your Businesses</p>
        </div>

        <div className={styles.cardContainer}>
          {businesses &&
            businesses.map((elem) => {
              return (
                <BusinessCard
                  businessId={elem.id}
                  businessName={elem.attributes.business_name}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
}
