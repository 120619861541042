import React, { useEffect, useRef } from "react";
import styles from "./ScheduleSelector.module.css";

const ScheduleSelectorDropdown = ({ schedules, onSelectSchedule, selectedSchedule }) => {
  const handleSelectSchedule = (schedule) => {
    onSelectSchedule(schedule);
  };

  const ref = useRef(null);

  useEffect(() => {
    if (selectedSchedule && ref.current) {
      ref.current.scrollIntoView({ block: 'center' });
    }
  }, [schedules, selectedSchedule]); 

  return (
    <div className={styles.dropdown}>
      {schedules.length === 0 && (
        <div className={styles.dropdownItem}>No matching schedules</div>
      )}
      {schedules.map((schedule) => (
        <div
          key={schedule.id}
          className={[styles.dropdownItem, (schedule === selectedSchedule) && styles.selectedDropdownItem].join(" ")}
          onClick={() => handleSelectSchedule(schedule)}
          ref={schedule === selectedSchedule ? ref : null}
        >
          {schedule.attributes.store_name}
        </div>
      ))}
    </div>
  );
};

export default ScheduleSelectorDropdown;
