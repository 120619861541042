export const generateTerminalInfoName = (storeName) => {
  // If storeName starts with a number, use a 0 padded 5 digit version of that.
  // Otherwise use the whole store name
  let fileName = "";
  const startingNumber = parseInt(storeName, 10);
  if (isNaN(startingNumber) || startingNumber < 0) {
    fileName = storeName;
  } else if (startingNumber > 99999) {
    fileName = '' + startingNumber;
  }
  else {
    fileName = ('00000' + startingNumber).slice(-5)
  }
  return fileName + "_http_terminal_conf.xml"
};

export const generateTerminalInfoFileBlob = (privateKey) => {
  return new Blob(['<?xml version="1.0"?>\n<TerminalConfig>\n   <TerminalName>TerminalClockName</TerminalName>\n   <PrivateKey>'+privateKey+'</PrivateKey>\n</TerminalConfig>\n'],
   {type: 'text/xml'});
}
