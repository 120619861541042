import React from "react";
import styles from "./NavBar.module.css";
import { ROUTE_LOG_IN } from "../../../utils/constants";
import { useNavigate } from "react-router-dom/dist";

const NavBarDropdown = ({ showDropdown }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate(`/${ROUTE_LOG_IN}`);
  };
  return (
    <>
      {showDropdown && (
        <div className={styles.dropdown}>
          <div className={styles.dropdownItem} onClick={handleLogout}>
            Logout
          </div>
          <div className={styles.dropdownItemVer}>LIFELENZ v1.59.0</div>
        </div>
      )}
    </>
  );
};

export default NavBarDropdown;
