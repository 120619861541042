import React, { useState, useEffect } from "react";
import styles from "./DeviceRegister.module.css";
import { getSchedules, registerDevice } from "../../api/api";
import RegisterDeviceDialogBox from "../common/DialogBox/RegisterDeviceDialogBox/RegisterDeviceDialogBox";
import { Helmet } from "react-helmet";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { ROUTE_DASHBOARD } from "../../utils/constants";

const DeviceRegister = ({ businessName, businessId, setSelectedComponent }) => {
  const [scheduleOptions, setScheduleOptions] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState("");

  const [deviceName, setDeviceName] = useState("");
  const [serialNo, setSerialNo] = useState("");
  const [error, setError] = useState("");

  const [dialogData, setDialogData] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchScheduleOptions = async () => {
      try {
        const response = await getSchedules(businessId);
        setScheduleOptions(response);
      } catch (error) {
        console.error("Error fetching schedule options:", error);
      }
    };
    fetchScheduleOptions();
  }, [businessId]);

  const schema = yup.object().shape({
    selectedSchedule: yup.string().required("Schedule is required"),
    deviceName: yup.string().required("Device name is required"),
    serialNo: yup.string().required("Serial number is required"),
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      await schema.validate(
        { selectedSchedule, deviceName, serialNo },
        { abortEarly: false }
      );
      const { data, error } = await registerDevice(
        businessId,
        selectedSchedule,
        deviceName,
        serialNo
      );
      if (data) {
        // Add the schedule name to the returned data
        // Find the schedule from the ID
        let scheduleEntry = scheduleOptions.find(s => s.id == selectedSchedule);
        data.store_name = scheduleEntry != null ? scheduleEntry.attributes.store_name : "";
        setShowDialog(true);
        setDialogData(data);
        clearForm();
        setLoading(false);
      } else {
        setError(error || "Unknown error occurred");
        setShowDialog(true);
      }
    } catch (validationError) {
      const errorMessage = validationError.inner.map((error) => error.message);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleScheduleChange = (event) => {
    setSelectedSchedule(event.target.value);
    setError("");
  };

  const clearForm = () => {
    setSelectedSchedule("");
    setDeviceName("");
    setSerialNo("");
    setLoading(false);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    setError("");
    setDialogData("");

    // setSelectedComponent("dashboard");
    // navigate(
    //   `/${ROUTE_DASHBOARD}?businessName=${encodeURIComponent(
    //     businessName
    //   )}&businessId=${encodeURIComponent(businessId)}`
    // );
  };

  return (
    <div className={styles.mainContainer}>
      <Helmet>
        <title>Device Registration | LIFELENZ</title>
      </Helmet>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <span className={styles.headerSpan}>
            Time Clock Device | Register New Device
          </span>
          <FontAwesomeIcon
            icon={faXmark}
            alt="Back"
            className={styles.backButton}
            style={{ display: "none" }}
            onClick={() => {
              setSelectedComponent("dashboard");
              navigate(
                `/${ROUTE_DASHBOARD}?businessName=${encodeURIComponent(
                  businessName
                )}&businessId=${encodeURIComponent(businessId)}`
              );
            }}
          />
        </div>
      </div>
      <main className={styles.mainH}>
        <div className={styles.registrationContainer}>
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.formGroup}>
              <label className={styles.label} htmlFor="businessName">
                Business Name
              </label>
              <input
                type="text"
                id="businessName"
                value={businessName}
                readOnly
                className={styles.input}
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label} htmlFor="scheduleLocation">
                Schedule Location
              </label>
              <select
                id="scheduleLocation"
                value={selectedSchedule}
                onChange={handleScheduleChange}
                className={styles.selectorInput}
                required
              >
                <option value="">Select Schedule</option>
                {scheduleOptions.map((schedule) => (
                  <option key={schedule.id} value={schedule.id}>
                    {schedule.attributes.store_name}
                  </option>
                ))}
              </select>
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label} htmlFor="deviceName">
                Device Name
              </label>
              <input
                type="text"
                id="deviceName"
                required
                value={deviceName}
                onChange={(e) => setDeviceName(e.target.value)}
                className={styles.input}
                maxLength={200}
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label} htmlFor="serialNo">
                Serial Number
              </label>
              <input
                type="text"
                id="serialNo"
                required
                value={serialNo}
                onChange={(e) => setSerialNo(e.target.value)}
                className={styles.input}
                maxLength={200}
              />
            </div>
            <div className={styles.submitButtonContainer}>
              <button
                type="submit"
                className={styles.submitButton}
                disabled={loading}
              >
                {loading ? <div className={styles.spinner}></div> : "Register"}
              </button>
            </div>
          </form>
        </div>
      </main>
      {showDialog && (
        <RegisterDeviceDialogBox
          dialogData={dialogData}
          onClose={handleCloseDialog}
          error={error}
        />
      )}
    </div>
  );
};

export default DeviceRegister;
