import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./BusinessCard.module.css";
import { ROUTE_DASHBOARD } from "../../../utils/constants";
import { getBusinessesImage } from "../../../api/api";

export default function BusinessCard({ businessId, businessName }) {
  const [hover, setHover] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);

  const navigate = useNavigate();

  const handleViewBtnClick = () => {
    navigate(
      `/${ROUTE_DASHBOARD}?businessName=${encodeURIComponent(
        businessName
      )}&businessId=${encodeURIComponent(businessId)}`
    );
  };

  useEffect(() => {
    const fetchImageSrc = async () => {
      try {
        const imageData = await getBusinessesImage(businessId);

        const blob = new Blob([imageData], { type: "image/png" });

        const reader = new FileReader();
        reader.onload = () => {
          setImageSrc(reader.result);
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error("Error fetching business image:", error);
      }
    };
    fetchImageSrc();
  }, [businessId]);

  return (
    <>
      <div
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        className={styles.card}
      >
        <div className={styles.blueContent} />
        {!hover ? (
          <div className={styles.mainContent}>
            <p className={styles.businessName}>{businessName}</p>
            {/* <p className={styles.msg}>Here to serve you ...</p> */}
          </div>
        ) : (
          <div className={styles.hoverContent}>
            <button className={styles.btnHover} onClick={handleViewBtnClick}>
              View
            </button>
          </div>
        )}
        {imageSrc && (
          <img className={styles.image} src={imageSrc} alt={businessName}></img>
        )}
      </div>
    </>
  );
}
