import React, { useState } from "react";
import styles from "./SideBar.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquarePlus,
  faExclamationTriangle,
  faFileArrowUp,
  faTachometerAlt,
} from "@fortawesome/free-solid-svg-icons";

export default function SideBar({ onSelect }) {
  const [selectedComponent, setSelectedComponent] = useState("dashboard");

  const handleSelect = (component) => {
    setSelectedComponent(component);
    onSelect(component);
  };

  return (
    <div className={styles.container}>
      <div className={styles.navigation}>
        <ul className={styles.ul}>
          <li className={styles.li}>
            <a
              href="#"
              className={styles.a}
              onClick={() => handleSelect("dashboard")}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Device Dashboard"
              data-tooltip-place="bottom"
            >
              <span className={styles.icon}>
                <FontAwesomeIcon
                  icon={faTachometerAlt}
                  alt="Device Dashboard"
                  className={`${styles.Faicon} ${
                    selectedComponent === "dashboard" ? styles.selected : ""
                  }`}
                  style={{
                    color:
                      selectedComponent === "dashboard" ? "#333" : undefined,
                  }}
                />
              </span>
            </a>
          </li>
          <li className={styles.li}>
            <a
              href="#"
              className={styles.a}
              onClick={() => handleSelect("register")}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Register Device"
              data-tooltip-place="bottom"
            >
              <span className={styles.icon}>
                <FontAwesomeIcon
                  icon={faSquarePlus}
                  alt="Register Device"
                  className={`${styles.Faicon} ${
                    selectedComponent === "register" ? styles.selected : ""
                  }`}
                  style={{
                    color:
                      selectedComponent === "register" ? "#333" : undefined,
                  }}
                />
              </span>
            </a>
          </li>
          <li className={styles.li}>
            <a
              href="#"
              className={styles.a}
              onClick={() => handleSelect("complianceError")}
              data-tip="Compliance Warnings"
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Compliance Warnings"
              data-tooltip-place="bottom"
            >
              <span className={styles.icon}>
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  alt="Compliance Errors"
                  className={`${styles.Faicon} ${
                    selectedComponent === "complianceError"
                      ? styles.selected
                      : ""
                  }`}
                  style={{
                    color:
                      selectedComponent === "complianceError"
                        ? "#333"
                        : undefined,
                  }}
                />
              </span>
            </a>
          </li>
          <li className={styles.li}>
            <a
              href="#"
              className={styles.a}
              onClick={() => handleSelect("dldList")}
              data-tip="DLD File Upload"
              data-tooltip-id="my-tooltip"
              data-tooltip-content="DLD File Upload"
              data-tooltip-place="bottom"
            >
              <span className={styles.icon}>
                <FontAwesomeIcon
                  icon={faFileArrowUp}
                  alt="DLD File Upload"
                  className={`${styles.Faicon} ${
                    selectedComponent === "dldList" ? styles.selected : ""
                  }`}
                  style={{
                    color: selectedComponent === "dldList" ? "#333" : undefined,
                  }}
                />
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
