import React from "react";
import styles from "./DeRegisterSuccess.module.css";

const DeRegisterSuccess = ({ onClose, message }) => {
  return (
    <div className={styles.overlay}>
      <div className={styles.dialog}>
        <div className={styles.title}>{message}</div>
        <div>
          <button onClick={onClose} className={styles.closeButton}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeRegisterSuccess;
