import React from "react";
import styles from "./WarningDialogBox.module.css";

const WarningDialogBox = ({ onUpload, onClose }) => {
  return (
    <div className={styles.dialogOverlay}>
      <div className={styles.dialog}>
        <h2 className={styles.dialogTitle}>Warning</h2>
        <p className={styles.dialogBody}>
          Uploading a new DLD file will affect all time clock devices.
          <br />
          Are you sure you want to proceed?
        </p>
        <div className={styles.buttonContainer}>
          <button className={styles.cancelBtn} onClick={onClose}>
            Cancel
          </button>
          <button className={styles.okBtn} onClick={onUpload}>
            OK, Proceed
          </button>
        </div>
      </div>
    </div>
  );
};

export default WarningDialogBox;
