import React from "react";
import styles from "./ErrorDialog.module.css";

const ErrorDialog = ({ title, detail, onClose }) => {
  return (
    <div className={styles.overlay}>
      <div className={styles.dialog}>
        <h2 className={styles.dialogTitle}>{title} </h2>
        <p className={styles.dialogBody}>{detail}</p>
        <div>
          <button className={styles.dialogBtn} onClick={onClose}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorDialog;
