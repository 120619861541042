import React, { useEffect, useState } from "react";
import styles from "./ScheduleSelector.module.css";
import ScheduleSelectorDropdown from "./ScheduleSelectorDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

export default function ScheduleSelector({
  businessName,
  schedules,
  onSelectSchedule,
  selectedSchedule,
}) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [filteredSchedules, setFilteredSchedules] = useState(schedules);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const handleOutsideClick = (event) => {
    if (!event.target.closest(`#schedule-selector`)) {
      setShowDropdown(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleSelectSchedule = (schedule) => {
    onSelectSchedule(schedule);
    setShowDropdown(false);
  };

  const searchBoxChanged = (newValue) => {
    setFilterText(newValue);
    return true;
  }

  const searchBoxClick = (event) => {
    event.stopPropagation();
  }

  const getFilteredValues = () => {
    if (filterText === "") {
      return schedules;
    } else {
      const regex = new RegExp(`.*${filterText}.*`, "i")
      let newFilteredSchedules = schedules.filter( s => regex.test(s.attributes.store_name) )
      return newFilteredSchedules;
    }
    return true;
  }

  return (
    <div className={styles.container} id="schedule-selector" onClick={toggleDropdown}>
      <div className={[styles.selectorContainer, showDropdown && styles.selectorContainerExpanded].join(" ")}>
        <div className={styles.div}>
          <div className={styles.selector}>
            <div className={styles.businessTitle}>{businessName}</div>

            <div className={styles.scheduleAndFilter}>
              {selectedSchedule && (
                <div className={styles.selectedSchedule}>
                  {selectedSchedule.attributes.store_name}
                </div>
              )}
              {showDropdown && (
                <div className={styles.searchDiv}>
                  <input
                  type="text"
                  id="typeahead"
                  required
                  value={filterText}
                  onChange={(e) => searchBoxChanged(e.target.value)}
                  onClick={searchBoxClick}
                  className={styles.filterInput}
                  maxLength={200}
                  />
                </div>
              )}
            </div>
          </div>

          <FontAwesomeIcon icon={faCaretDown} className={styles.icon} />
        </div>
      </div>

      {showDropdown && (
        <ScheduleSelectorDropdown
          schedules={getFilteredValues()}
          onSelectSchedule={handleSelectSchedule}
          selectedSchedule={selectedSchedule}
        />
      )}
    </div>
  );
}
