import React from "react";
import styles from "./FileUploadDialogBox.module.css";

const FileUploadDialogBox = ({ message, onClose }) => {
  return (
    <div className={styles.dialogOverlay}>
      <div className={styles.dialog}>
        <h2 className={styles.dialogTitle}>{message} </h2>
        <p className={styles.dialogBody}></p>
        <button className={styles.dialogBtn} onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default FileUploadDialogBox;
