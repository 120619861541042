import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./SplashScreen.module.css";
import logo from "./../../assets/logo-lifelenz-white.svg";
import { ROUTE_LOG_IN } from "../../utils/constants";
import { Helmet } from "react-helmet";

export default function SplashScreen() {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(`/${ROUTE_LOG_IN}`);
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <section className={styles.splashScreen}>
      <Helmet>
        <title>LIFELENZ</title>
      </Helmet>
      <section className={styles.loadingBar}>
        <img
          src={logo}
          alt="LIFELENZ WebAdmin Loading"
          className={styles.lifelenzLogo}
        />
        <div className={styles.styleLoadingBar}></div>
      </section>
    </section>
  );
}
